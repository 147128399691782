import { Box, Stack, Typography, useMediaQuery, Grid } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import { Trans } from "@lingui/macro";
import styled from "styled-components";
import Container from "./Container";
import SecondaryButton from "./SecondaryButton";
import AnimatedDiv from "./AnimatedDiv";

const settings = {
  dots: false,
  infinite: true,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  fade: true,
  autoplaySpeed: 2000,
  cssEase: "linear",
};

function HeroLanding({
  kind,
  title,
  description,
  isImageToBorder,
  widthImage = { xs: "80%", md: "100%" },
  isSlider = false,
  isVideo = false,
  multimediaSide = "right",
  kindColor,
  handleOnclickInfo,
  ButtonRequestInfoText = <Trans>Request info</Trans>,
  grid = {
    columnRight: {
      md: isVideo ? 5 : 6,
    },
    columnLeft: {
      md: isVideo ? 7 : 6,
    },
  },
  multimedia,
  titleSize,
  buttonJustify,
  kindImg,
  videoPoster,
}) {
  const isLessThan1440 = useMediaQuery((theme) => theme.breakpoints.down("1440"));
  const isGreaterThan900 = useMediaQuery((theme) => theme.breakpoints.up("900"));

  const isLaptopView = isLessThan1440 && isGreaterThan900;
  const isTablet = useMediaQuery((theme) => theme.breakpoints.between("sm", "md"));
  const isMobil = useMediaQuery((theme) => theme.breakpoints.between("xs", "sm"));

  const renderContent = (_title, _description) => {
    const currentTitle = title ?? _title;
    const currentDescription = description ?? _description;
    const currentTitleSize = titleSize ?? { xs: 36, sm: 48, lg: 60 };
    const currentButtonJustify = buttonJustify ?? { xs: "center", sm: "center", lg: "flex-start"};

    return (
      <Container style={{ overflow: "hidden" }}>
        <Grid
          container
          py={{ xs: 4.3, sm: 6, md: 6, lg: 12 }}
          sx={{ position: "relative" }}
          spacing={2}
          flexDirection={multimediaSide === "left" ? "row-reverse" : "row"}
        >
          <Grid
            item
            xs={12}
            alignContent="center"
            md={grid.columnLeft.md}
          >
            <Stack spacing={1}>
              {kindImg && (
              <Box width="160px" display={{ xs: "none", md: "block" }} pb={3}>
                <img src={kindImg} alt="kind" width="100%" style={{ objectFit: "contain" }} />
              </Box>
                )}
              {kind && (
              <Typography fontWeight={700} fontSize={24} color={kindColor}>
                {kind}
              </Typography>
                )}
              <Stack spacing={2}>
                {currentTitle && (
                <Box>
                  <Typography variant="h1" fontSize={currentTitleSize}>
                    {currentTitle}
                  </Typography>
                </Box>
              )}
                {currentDescription && (
                <Box>
                  <Typography fontSize={{ xs: 18, sm: 20 }}>
                    {currentDescription}
                  </Typography>
                </Box>
              )}
                <Box display={{ xs: "flex", lg: "inline-block" }} justifyContent={currentButtonJustify}>
                  <SecondaryButton
                    handleOnclickInfo={handleOnclickInfo}
                    text={ButtonRequestInfoText}
                />
                </Box>
              </Stack>
            </Stack>
          </Grid>
          <Grid
            item
            pt={{ xs: 0, sm: 6, md: 0, lg: 0 }}
            xs={12}
            md={grid.columnRight.md}
            style={{ position: "relative" }}
            display="flex"
            alignContent="center"
            justifyContent={{ xs: "center", sm: "center", md: "flex-end", lg: "flex-end" }}>
            { isSlider &&
              <Box style={{ width: "100%", maxWidth: 400 }}>
                <Slider {...settings}>
                  {multimedia.map((item, index) => (
                    <Box key={index}>
                      <img src={item.image} alt={`carousel img ${index + 1}`} style={{ objectFit: "contain"}} width="100%" />
                    </Box>
                ))}
                </Slider>
              </Box>}
            { !isSlider &&
              <>
                { isVideo && (
                  <StyledBox
                    mt={{ xs: 4, sm: 6, md: 0, lg: 0 }}
                    isLaptopView={isLaptopView}
                    isImageToBorder={isImageToBorder}
                    top="32%"
                    isTablet={isTablet}
                    isMobil={isMobil}
                    alignItems="center"
                    justifyItems="center">
                    <video
                      alt="first_video"
                      muted
                      autoPlay
                      playsInline
                      style={isTablet ? {width: "80%", display: "block" } : { width: "100%", display: "block" }}
                      src={multimedia}
                      poster={videoPoster}
                    />
                  </StyledBox>
                )}
                { !isVideo && (
                  <StyledBox pt={{ xs: 6, sm: 6, md: 0, lg: 0 }} isLaptopView={isLaptopView} isImageToBorder={isImageToBorder} top="50%">
                    <AnimatedDiv direction="right">
                      <Box display="flex" justifyContent="center">
                        <Box width={widthImage}>
                          <img
                            src={multimedia}
                            alt={kind}
                            width="100%"
                      />
                        </Box>
                      </Box>
                    </AnimatedDiv>
                  </StyledBox>
                )}
              </>}
          </Grid>
        </Grid>
      </Container>
    );
  };

  return (
    <Box>
      {renderContent()}
    </Box>
  );
}

export default HeroLanding;

const StyledBox = styled(Box)(({ isLaptopView, isImageToBorder, top, isTablet = false, isMobil = false}) => {
  const newTop = isMobil ? "40%" : isTablet ? top : "50%";

  return (
    {
      display: isTablet ? "flex" : "unset",
      justifyContent: "center",
      position: (isLaptopView && isImageToBorder) ? "absolute" : "relative",
      right: (isLaptopView && isImageToBorder) ? "-45%" : "0",
      top: `${newTop} !important`,
      transform: "translate(0%, -50%)",
    }
  );
});
